import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Rails from 'rails-ujs';
import 'bootstrap/dist/js/bootstrap';

import { setupTranslations } from "../src/javascripts/services/translations";
import { BlockingBroadcastGraph } from "../src/javascripts/charts/BlockingBroadcastGraph";

Rails.start();
setupTranslations();
window.$ = jQuery;
require.context('../images', true);

import "chartkick/chart.js"
import '../../../node_modules/highcharts';
import '../../webpacker/src/javascripts/ie_check';
import '../../webpacker/src/javascripts/components/messages/Messenger';
import '../../webpacker/src/javascripts/dynamic_button';
import '../../webpacker/src/javascripts/message_counter';
import '../../webpacker/src/javascripts/widget_settings';
import '../../webpacker/src/javascripts/panelToggles';
import '../../webpacker/src/javascripts/submit_file_upload';
import '../src/javascripts/collapse_faqs';
import '../src/javascripts/recaptcha';
import '../src/javascripts/google_analytics';
import '../src/javascripts/coupons';
import 'controllers';

const initCharts = () => {
  google.charts.load('current', {packages: ['corechart'], language: 'de'});
  google.charts.setOnLoadCallback(new BlockingBroadcastGraph().init);
};

$(document).ready(() => {
  if ($('[data-blocking-users-count-per-day]').length) {
    initCharts();
  }

  if ($('#profile-panel').length) {
    const tab = location.hash.replace(/^#/, '');
    $('button[data-settings="' + tab + '"]').trigger('click');
  }
});
