export default function initShowFaqClickListener() {
  $('[data-show-faq]').on('click', (event) => {
    const broadcastCategory = $(event.target).data('show-faq');
    ($(`#faq-${broadcastCategory}`) as any).collapse('show');
    location.href = `#faq-${broadcastCategory}-title`;
    return false;
  });
}

$(initShowFaqClickListener);
