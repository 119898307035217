export default class WidgetSettings {
  appViewElement: JQuery<HTMLElement>;
  allMessagesElement: JQuery<HTMLElement>;
  codeElement: JQuery<HTMLElement>;
  iframeElement: JQuery<HTMLElement>;
  baseUrl: string;
  shopId: number;
  hubId: number;

  constructor(appViewElement: JQuery<HTMLElement>, allMessagesElement: JQuery<HTMLElement>,
              codeElement: JQuery<HTMLElement>, iframeElement: JQuery<HTMLElement>) {

    this.appViewElement = appViewElement;
    this.allMessagesElement = allMessagesElement;
    this.codeElement = codeElement;
    this.iframeElement = iframeElement;

    this.baseUrl = iframeElement.attr('src').split('&')[0];
    this.shopId = parseInt(iframeElement.attr('src').split('shop_id=')[1], 10);
    this.hubId = iframeElement.data('hub-id');

    appViewElement.change(() => { this.updateUrl(); });
    allMessagesElement.change(() => { this.updateUrl(); });
  }

  updateUrl() {
    let url: string = this.baseUrl;
    let width: number = 300;
    let height: number = 550;

    if (!this.allMessagesElement.is(':checked')) {
      url += '&shop_id=' + this.shopId;
    } else {
      url += '&hub_id=' + this.hubId;
    }

    if (!this.appViewElement.is(':checked')) {
      width = 360;
      height = 480;
      url += '&canvas=false';
    }

    this.codeElement.text(`<iframe width="${width}" height="${height}" frameborder="0" src="${url}"></iframe>`);
    this.iframeElement.attr('src', url);
    this.iframeElement.attr('width', width);
    this.iframeElement.attr('height', height);
  }
}

export const onReady = (): WidgetSettings => {
  if (!$('#widget-settings-iframe').length) { return; }
  return new WidgetSettings($('#widget-settings-app-view'), $('#widget-settings-all-messages'),
                            $('#widget-settings-code'), $('#widget-settings-iframe'));
};

$(document).ready(onReady);
