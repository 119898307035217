import t from 'src/javascripts/services/translations';

export class BlockingBroadcastGraph {
  daysSinceFirstBlocking: string[];
  broadcastMessagesPerDay: Array<[]>;
  blockingUsersCountPerDay: number[];
  columnChartElement: HTMLElement;

  chartOptions = {
    title: t('statistics.charts.labels.title'),
    colors: ['#0fe70f'],
    vAxis: {
     title: t('statistics.charts.labels.blockings'),
    },
    legend: { position: 'none' as google.visualization.ChartLegendPosition },
    hAxis: {
      gridlines: { color: 'transparent' },
    },
    annotations: {
      alwaysOutside: true,
      textStyle: {
        color: '#009dff',
      },
    },
    explorer: {
      actions: ['dragToZoom', 'rightClickToReset'],
      axis: 'horizontal', keepInBounds: true },
  };

  constructor() {
    const $blockingUsersChart = $('[data-blocking-users-count-per-day]');
    this.columnChartElement = $blockingUsersChart[0];
    this.blockingUsersCountPerDay = $blockingUsersChart.data('blocking-users-count-per-day');
    this.daysSinceFirstBlocking = $blockingUsersChart.data('days-since-first-blocking').map(day => new Date(day));
    this.broadcastMessagesPerDay = $blockingUsersChart.data('broadcast-messages-per-day');
  }

  init = () => {
    const maxMessagesCount = Math.max(...this.broadcastMessagesPerDay.map(messages => messages.length));
    const chart = new google.visualization.ColumnChart(this.columnChartElement);
    google.visualization.events.addListener(chart, 'error', error => {
      chart.clearChart();
      console.error(error);
    });
    chart.draw(this.getData(maxMessagesCount), this.chartOptions);
  }

  getRows(maxMessagesCount) {
    return this.daysSinceFirstBlocking.map((day, index) => {
      const broadcastMessages = this.broadcastMessagesPerDay[index];
      const labelsAndText = [];
      broadcastMessages.forEach(broadcastMessage => labelsAndText.push('BN', broadcastMessage));
      while (labelsAndText.length < maxMessagesCount * 2) {
        labelsAndText.push(undefined, undefined);
      }
      return [day, this.blockingUsersCountPerDay[index], ...labelsAndText];
    });
  }

  private getData(maxMessagesCount) {
    const data = new google.visualization.DataTable();
    data.addColumn('date', 'day');
    data.addColumn('number', t('statistics.charts.labels.blockings'));
    Array.from({ length: maxMessagesCount }, () => {
      data.addColumn({ type: 'string', role: 'annotation' });
      data.addColumn({ type: 'string', role: 'annotationText' });
    });
    data.addRows(this.getRows(maxMessagesCount));
    return data;
  }
}
