export default function onReady() {
  const parent = $('[data-toggle="section"]').first();

  if (!parent.length) {
    return;
  }

  const key = parent.attr('id');
  const sections = parent.next().find(parent.data('toggle'));
  const activateSection = (index: number) => {
    sections.removeClass('active');
    $(sections[index]).addClass('active');
  };
  const buttons = parent.find('button');
  const activateButton = (index: number) => {
    buttons.removeClass('btn-primary').addClass('btn-secondary');
    $(buttons[index]).removeClass('btn-secondary').addClass('btn-primary');
  };
  const openIndex = Number(sessionStorage.getItem(key)) || 0;
  activateSection(openIndex);
  activateButton(openIndex);

  $(sections[openIndex]).find('.error_explanation').addClass('keep');
  parent.next().find('.error_explanation:not(.keep)').remove();

  buttons.each((index, btn) => {
    $(btn).on('click', () => {
      activateButton(index);
      activateSection(index);
      sessionStorage.setItem(key, ''+index);
    });
  });
}

$(document).ready(onReady);
