export default class MessageCounter {
  maxLength: string;
  messageInput: JQuery;
  placeholderElement: JQuery;
  currentLength: number;

  attach(inputElement: JQuery, placeholderElement: JQuery) {
    this.maxLength = inputElement.attr('maxlength');
    this.messageInput = inputElement;
    this.placeholderElement = placeholderElement;
    this.messageInput.on('keyup', () => {
      this.renderLength();
    });
    this.renderLength();
  }

  renderLength() {
    this.currentLength = (this.messageInput.val() as string).length;
    this.placeholderElement.text(`${this.currentLength}/${this.maxLength}`);
  }
}

export const onReady = () => {
  $('[data-message-counter]').each((index, obj) => {
    const placeholderElement = $(obj);
    const inputElement = $('#' + placeholderElement.attr('data-message-counter'));
    new MessageCounter().attach(inputElement, placeholderElement);
  });
};

$(document).ready(onReady);
